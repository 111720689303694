import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
import Box from "common/components/Box"
import Text from "common/components/Text"
import Heading from "common/components/Heading"
import Card from "common/components/Card"
import GatsbyImage from "common/components/GatsbyImage"
import FeatureBlock from "common/components/FeatureBlock"
import Container from "common/components/UI/Container"
import iconlogo from "common/assets/image/appModern/branding book updated -31.png"
import QualitySectionWrapper from "./qualitySection.style"
import { isMobile } from "react-device-detect"

const QualitySection = ({
  row,
  col,
  title,
  featureCol,
  description,
  textArea,
  imageArea,
  imageAreaRow,
  imageWrapper,
  featureTitle,
  featureDescription,
  iconStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      agencyJson {
        qualityFeature {
          description
          icon
          id
          title
        }
      }
      image: file(relativePath: { eq: "image/agency/surface-studio.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  return (
    <QualitySectionWrapper id="qualitySection">
      <Container>
        <Box className="row">
          <Box className="col-12">
            <FeatureBlock
              title={
                <Heading
                  content="Interpretation and Definitions"
                  {...title}
                  style={{ color: "#f6c04d", textAlign: "center" }}
                />
              }
              description={
                <>
                  <Box className="col-12">
                    <FeatureBlock
                      title={
                        <Heading
                          style={{ color: "#f6c04d", textAlign: "center" }}
                          content={
                            <>
                              <img
                                src={iconlogo}
                                style={{ width: isMobile ? "6vw" : "2vw" }}
                              ></img>
                              <span style={{ fontSize: "28px" }}>
                                Interpretation
                              </span>
                            </>
                          }
                          {...title}
                        />
                      }
                      description={
                        <Text
                          style={{ color: "#fff" }}
                          content="The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural."
                          {...description}
                        />
                      }
                    />
                  </Box>
                  <Box className="col-12">
                    <FeatureBlock
                      title={
                        <Heading
                          style={{ color: "#f6c04d", textAlign: "center" }}
                          content={
                            <>
                              <img
                                src={iconlogo}
                                style={{ width: isMobile ? "6vw" : "2vw" }}
                              ></img>
                              <span style={{ fontSize: "28px" }}>
                                Definitions:
                              </span>
                            </>
                          }
                          {...title}
                        />
                      }
                      description={
                        <>
                          <Text
                            style={{ color: "#fff", fontSize: "22px" }}
                            content="- For the purposes of this Privacy Policy:"
                            {...description}
                          />
                          <Text
                            style={{ color: "#fff", whiteSpace: "pre-wrap" }}
                            content={
                              <>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - Account:
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      " means a unique account created for You to access our Service or parts of our Service."
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - Affiliate:
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      " means an entity that controls, is controlled by or is under common control with a party, where 'control' means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority."
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - Application:
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      " refers to Dayra, the software program provided by the Company."
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - Company:
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      "  (referred to as either 'the Company', 'We', 'Us' or 'Our' in this Agreement) refers to NEON, 13 Ahmed Fouad Street, El Nozha, Heliopolis, Cairo, Egypt.."
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - Country:
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {"  refers to: Egypt"}
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - Device:
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      "  means any device that can access the Service such as a computer, a cellphone or a digital tablet."
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - Personal Data:
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      "  is any information that relates to an identified or identifiable individual."
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - Service:
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {"  refers to the Application."}
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - Service Provider:
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      "  means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used."
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - Third-party Social Media Service:
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      "  refers to any website or any social network website through which a User can log in or create an account to use the Service."
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - Usage Data:
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      "  refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit)."
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - You:
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      "  means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable."
                                    }
                                  </span>
                                </p>
                              </>
                            }
                            {...description}
                          />
                        </>
                      }
                    />
                  </Box>
                </>
              }
            />
          </Box>
          <Box className="col-12">
            <FeatureBlock
              title={
                <Heading
                  content="Collecting and Using Your Personal Data"
                  {...title}
                  style={{ color: "#f6c04d", textAlign: "center" }}
                />
              }
              description={
                <>
                  <Box className="col-12">
                    <FeatureBlock
                      title={
                        <Heading
                          style={{ color: "#f6c04d", textAlign: "center" }}
                          content={
                            <>
                              <img
                                src={iconlogo}
                                style={{ width: isMobile ? "6vw" : "2vw" }}
                              ></img>
                              <span style={{ fontSize: "28px" }}>
                                Types of Data Collected
                              </span>
                            </>
                          }
                          {...title}
                        />
                      }
                      description={
                        <>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - Personal Data
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        While using Our Service, We may ask You
                                        to provide Us with certain personally
                                        identifiable information that can be
                                        used to contact or identify You.
                                        Personally identifiable information may
                                        include, but is not limited to:
                                      </p>
                                      <ul>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" Email address"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" Vehicle License (Front and back)"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" Driving License (Front and back)"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" Car Model, Make, and Brand"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" Plate Numbers"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" First name and last name"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" Phone number"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {
                                            " Address, State, Province, ZIP/Postal code, City"
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" Usage Data"}
                                        </li>
                                      </ul>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - Usage Data
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        Usage Data is collected automatically
                                        when using the Service.
                                      </p>
                                      <p>
                                        Usage Data may include information such
                                        as Your Device's Internet Protocol
                                        address (e.g. IP address), browser type,
                                        browser version, the pages of our
                                        Service that You visit, the time and
                                        date of Your visit, the time spent on
                                        those pages, unique device identifiers
                                        and other diagnostic data.
                                      </p>
                                      <p>
                                        When You access the Service by or
                                        through a mobile device, We may collect
                                        certain information automatically,
                                        including, but not limited to, the type
                                        of mobile device You use, Your mobile
                                        device unique ID, the IP address of Your
                                        mobile device, Your mobile operating
                                        system, the type of mobile Internet
                                        browser You use, unique device
                                        identifiers and other diagnostic data.
                                      </p>
                                      <p>
                                        We may also collect information that
                                        Your browser sends whenever You visit
                                        our Service or when You access the
                                        Service by or through a mobile device.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - Information from Third-Party Social
                                        Media Services
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        The Company allows You to create an
                                        account and log in to use the Service
                                        through the following Third-party Social
                                        Media Services:
                                      </p>
                                      <ul>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" Google"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" Facebook"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" Instagram"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" Twitter"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" LinkedIn"}
                                        </li>
                                      </ul>
                                      <p>
                                        If You decide to register through or
                                        otherwise grant us access to a
                                        Third-Party Social Media Service, We may
                                        collect Personal data that is already
                                        associated with Your Third-Party Social
                                        Media Service's account, such as Your
                                        name, Your email address, Your
                                        activities or Your contact list
                                        associated with that account.
                                      </p>
                                      <p>
                                        You may also have the option of sharing
                                        additional information with the Company
                                        through Your Third-Party Social Media
                                        Service's account. If You choose to
                                        provide such information and Personal
                                        Data, during registration or otherwise,
                                        You are giving the Company permission to
                                        use, share, and store it in a manner
                                        consistent with this Privacy Policy.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - Information Collected while Using the
                                        Application
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        While using Our Application, in order to
                                        provide features of Our Application, We
                                        may collect, with Your prior permission:
                                      </p>
                                      <ul>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {
                                            " Information regarding your location"
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {
                                            " Information from your Device's phone book (contacts list)"
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {
                                            " Pictures and other information from your Device's camera and photo library"
                                          }
                                        </li>
                                      </ul>
                                      <p>
                                        We use this information to provide
                                        features of Our Service, to improve and
                                        customize Our Service. The information
                                        may be uploaded to the Company's servers
                                        and/or a Service Provider's server or it
                                        may be simply stored on Your device.
                                      </p>
                                      <p>
                                        You can enable or disable access to this
                                        information at any time, through Your
                                        Device settings.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - Use of Your Personal Data
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        The Company may use Personal Data for
                                        the following purposes:
                                      </p>
                                      <ul>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • To provide and maintain our
                                            Service:
                                          </span>

                                          {
                                            "  including to monitor the usage of our Service."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • To manage Your Account:
                                          </span>
                                          {
                                            " to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • For the performance of a contract:
                                          </span>
                                          {
                                            " the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • To contact You:
                                          </span>
                                          {
                                            " To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • To provide You:
                                          </span>
                                          {
                                            " with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • To manage Your requests:
                                          </span>
                                          {
                                            " To attend and manage Your requests to Us."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • For business transfers:
                                          </span>
                                          {
                                            " We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • For other purposes:
                                          </span>
                                          {
                                            " We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience."
                                          }
                                        </li>
                                      </ul>
                                      <p>
                                        We may share Your personal information
                                        in the following situations:
                                      </p>
                                      <ul>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • With Service Providers:
                                          </span>
                                          {
                                            " We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • For business transfers:
                                          </span>
                                          {
                                            " We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • With Affiliates:
                                          </span>
                                          {
                                            " We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • With business partners:
                                          </span>
                                          {
                                            " We may share Your information with Our business partners to offer You certain products, services or promotions."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • With other users:
                                          </span>
                                          {
                                            " when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • With Your consent:
                                          </span>
                                          {
                                            " We may disclose Your personal information for any other purpose with Your consent."
                                          }
                                        </li>
                                      </ul>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - Retention of Your Personal Data
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        The Company will retain Your Personal
                                        Data only for as long as is necessary
                                        for the purposes set out in this Privacy
                                        Policy. We will retain and use Your
                                        Personal Data to the extent necessary to
                                        comply with our legal obligations (for
                                        example, if we are required to retain
                                        your data to comply with applicable
                                        laws), resolve disputes, and enforce our
                                        legal agreements and policies.
                                      </p>
                                      <p>
                                        The Company will also retain Usage Data
                                        for internal analysis purposes. Usage
                                        Data is generally retained for a shorter
                                        period of time, except when this data is
                                        used to strengthen the security or to
                                        improve the functionality of Our
                                        Service, or We are legally obligated to
                                        retain this data for longer time
                                        periods.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - Transfer of Your Personal Data
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        Your information, including Personal
                                        Data, is processed at the Company's
                                        operating offices and in any other
                                        places where the parties involved in the
                                        processing are located. It means that
                                        this information may be transferred to —
                                        and maintained on — computers located
                                        outside of Your state, province, country
                                        or other governmental jurisdiction where
                                        the data protection laws may differ than
                                        those from Your jurisdiction.
                                      </p>
                                      <p>
                                        Your consent to this Privacy Policy
                                        followed by Your submission of such
                                        information represents Your agreement to
                                        that transfer.
                                      </p>
                                      <p>
                                        The Company will take all steps
                                        reasonably necessary to ensure that Your
                                        data is treated securely and in
                                        accordance with this Privacy Policy and
                                        no transfer of Your Personal Data will
                                        take place to an organization or a
                                        country unless there are adequate
                                        controls in place including the security
                                        of Your data and other personal
                                        information.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - Delete Your Personal Data
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        You have the right to delete or request
                                        that We assist in deleting the Personal
                                        Data that We have collected about You.
                                      </p>
                                      <p>
                                        Our Service may give You the ability to
                                        delete certain information about You
                                        from within the Service.
                                      </p>
                                      <p>
                                        You may update, amend, or delete Your
                                        information at any time by signing in to
                                        Your Account, if you have one, and
                                        visiting the account settings section
                                        that allows you to manage Your personal
                                        information. You may also contact Us to
                                        request access to, correct, or delete
                                        any personal information that You have
                                        provided to Us.
                                      </p>
                                      <p>
                                        Please note, however, that We may need
                                        to retain certain information when we
                                        have a legal obligation or lawful basis
                                        to do so.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                        </>
                      }
                    />
                  </Box>
                  <Box className="col-12">
                    <FeatureBlock
                      title={
                        <Heading
                          style={{ color: "#f6c04d", textAlign: "center" }}
                          content={
                            <>
                              <img
                                src={iconlogo}
                                style={{ width: isMobile ? "6vw" : "2vw" }}
                              ></img>
                              <span style={{ fontSize: "28px" }}>
                                Disclosure of Your Personal Data
                              </span>
                            </>
                          }
                          {...title}
                        />
                      }
                      description={
                        <>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - Business Transactions
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        If the Company is involved in a merger,
                                        acquisition or asset sale, Your Personal
                                        Data may be transferred. We will provide
                                        notice before Your Personal Data is
                                        transferred and becomes subject to a
                                        different Privacy Policy.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - Law enforcement
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        Under certain circumstances, the Company
                                        may be required to disclose Your
                                        Personal Data if required to do so by
                                        law or in response to valid requests by
                                        public authorities (e.g. a court or a
                                        government agency).
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - Other legal requirements
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        The Company may disclose Your Personal
                                        Data in the good faith belief that such
                                        action is necessary to:
                                      </p>
                                      <ul>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" Comply with a legal obligation"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {
                                            " Protect and defend the rights or property of the Company"
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {
                                            " Prevent or investigate possible wrongdoing in connection with the Service"
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {
                                            " Protect the personal safety of Users of the Service or the public"
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" Protect against legal liability"}
                                        </li>
                                      </ul>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - Security of Your Personal Data
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        The security of Your Personal Data is
                                        important to Us, but remember that no
                                        method of transmission over the
                                        Internet, or method of electronic
                                        storage is 100% secure. While We strive
                                        to use commercially acceptable means to
                                        protect Your Personal Data, We cannot
                                        guarantee its absolute security.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - Children's Privacy
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        Our Service does not address anyone
                                        under the age of 18. We do not knowingly
                                        collect personally identifiable
                                        information from anyone under the age of
                                        18. If You are a parent or guardian and
                                        You are aware that Your child has
                                        provided Us with Personal Data, please
                                        contact Us. If We become aware that We
                                        have collected Personal Data from anyone
                                        under the age of 18 without verification
                                        of parental consent, We take steps to
                                        remove that information from Our
                                        servers.
                                      </p>
                                      <p>
                                        If We need to rely on consent as a legal
                                        basis for processing Your information
                                        and Your country requires consent from a
                                        parent, We may require Your parent's
                                        consent before We collect and use that
                                        information.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - Links to Other Websites
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        Our Service may contain links to other
                                        websites that are not operated by Us. If
                                        You click on a third party link, You
                                        will be directed to that third party's
                                        site. We strongly advise You to review
                                        the Privacy Policy of every site You
                                        visit.
                                      </p>
                                      <p>
                                        We have no control over and assume no
                                        responsibility for the content, privacy
                                        policies or practices of any third party
                                        sites or services.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - Changes to this Privacy Policy
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        We may update Our Privacy Policy from
                                        time to time. We will notify You of any
                                        changes by posting the new Privacy
                                        Policy on this page.
                                      </p>
                                      <p>
                                        We will let You know via email and/or a
                                        prominent notice on Our Service, prior
                                        to the change becoming effective and
                                        update the "Last updated" date at the
                                        top of this Privacy Policy.
                                      </p>
                                      <p>
                                        You are advised to review this Privacy
                                        Policy periodically for any changes.
                                        Changes to this Privacy Policy are
                                        effective when they are posted on this
                                        page.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                        </>
                      }
                    />
                  </Box>
                </>
              }
            />
          </Box>
          <Box className="col-12">
            <FeatureBlock
              title={
                <Heading
                  style={{ color: "#f6c04d", textAlign: "center" }}
                  content={
                    <>
                      <img
                        src={iconlogo}
                        style={{ width: isMobile ? "8vw" : "3vw" }}
                      ></img>
                      <span>CONTACT US</span>
                    </>
                  }
                  {...title}
                />
              }
              description={
                <Box className="col-12">
                  <FeatureBlock
                    title={
                      <Heading
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          fontSize: "32px",
                        }}
                        content={
                          <>
                            -
                            <span>
                              To resolve a complaint regarding the Services or
                              to receive further information regarding use of
                              the Services, please contact us at:
                            </span>
                          </>
                        }
                        {...title}
                      />
                    }
                    description={
                      <Text
                        style={{ color: "#fff", whiteSpace: "pre-wrap" }}
                        content={
                          <>
                            <p
                              style={{
                                color: "#f6c04d",
                              }}
                            >
                              - Company Name:
                              <span
                                style={{
                                  color: "#fff",
                                }}
                              >
                                {
                                  " Neon for Advertising and Technological Services."
                                }
                              </span>
                            </p>
                            <p
                              style={{
                                color: "#f6c04d",
                              }}
                            >
                              - Address:
                              <span
                                style={{
                                  color: "#fff",
                                }}
                              >
                                {
                                  " 13 Ahmed Fouad Street, El Nozha, Heliopolis, Cairo, Egypt."
                                }
                              </span>
                            </p>
                            <p
                              style={{
                                color: "#f6c04d",
                              }}
                            >
                              - Phone Number:
                              <span
                                style={{
                                  color: "#fff",
                                }}
                              >
                                {" +201148866775"}
                              </span>
                            </p>
                            <p
                              style={{
                                color: "#f6c04d",
                              }}
                            >
                              - Email:
                              <span
                                style={{
                                  color: "#fff",
                                }}
                              >
                                {" supp.dayra@gmail.com"}
                              </span>
                            </p>
                          </>
                        }
                        {...description}
                      />
                    }
                  />
                </Box>
              }
            />
          </Box>
          // -------------------------------------------------------------------
        </Box>
        {/* <Box className="row" {...row} {...textArea}>
          {Data.agencyJson.qualityFeature.map((feature, index) => (
            <Box
              className="col"
              {...featureCol}
              key={`quality_feature-${index}`}
            >
              <FeatureBlock
                icon={<i className={feature.icon} />}
                iconPosition="left"
                iconStyle={iconStyle}
                title={<Heading content={feature.title} {...featureTitle} />}
                description={
                  <Text content={feature.description} {...featureDescription} />
                }
              />
            </Box>
          ))}
        </Box> */}
      </Container>
      {/* <Container fluid noGutter className="info-sec-container">
        <Box className="row" {...row} {...imageAreaRow}>
          <Box className="col" {...col} {...imageArea}>
            <Card {...imageWrapper}>
              <Fade right delay={90}>
                <GatsbyImage
                  src={
                    (Data.image !== null) | undefined
                      ? Data.image.childImageSharp.gatsbyImageData
                      : {}
                  }
                  alt="Feature Image"
                />
              </Fade>
            </Card>
          </Box>
        </Box>
      </Container> */}
    </QualitySectionWrapper>
  )
}

QualitySection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  featureCol: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
}

QualitySection.defaultProps = {
  // Quality section row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // Quality section iamge row default style
  imageAreaRow: {
    flexDirection: "row-reverse",
  },
  // Quality section col default style
  col: {
    pr: "15px",
    pl: "15px",
  },
  // Quality feature col default style
  featureCol: {
    width: [1, 1, 1 / 2],
    pr: "15px",
    pl: "15px",
  },
  // Quality section text area default style
  textArea: {
    width: [1, "100%", "100%", "70%", "64%"],
  },
  // Quality section image area default style
  imageArea: {
    width: [1, "100%", "100%", "30%", "38%"],
    flexBox: true,
    flexDirection: "row-reverse",
  },
  // Quality section image wrapper default style
  imageWrapper: {
    boxShadow: "none",
    width: "100%",
  },
  // Quality section title default style
  title: {
    fontSize: ["26px", "26px", "32px", "40px"],
    lineHeight: "1.5",
    fontWeight: "300",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "20px",
  },
  // Quality section description default style
  description: {
    fontSize: "16px",
    color: "#343d48cc",
    lineHeight: "2.1",
    mb: "33px",
  },
  // feature icon default style
  iconStyle: {
    width: "54px",
    height: "54px",
    borderRadius: "50%",
    bg: "#93d26e",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "24px",
    color: "#ffffff",
    overflow: "hidden",
    mt: "6px",
    mr: "22px",
    flexShrink: 0,
  },
  // feature title default style
  featureTitle: {
    fontSize: ["18px", "20px"],
    fontWeight: "400",
    color: "#0f2137",
    lineHeight: "1.5",
    mb: "8px",
    letterSpacing: "-0.020em",
  },
  // feature description default style
  featureDescription: {
    fontSize: "15px",
    lineHeight: "1.84",
    color: "#343d48cc",
  },
}

export default QualitySection
